//demo styles
table#example{
    padding: 0rem 0 2rem 0;
}
table.dataTable{
    font-size: 0.875rem;
	color:var(--text-gray);
	.form-check{
		display: inline-block;
	}
}
#example2_wrapper {
    .dataTables_scrollBody {
        max-height: 33.25rem !important;
    }
}

#employees, #custommers {
    padding: .5rem 0 1rem 0;
}
//////////
.dataTables_wrapper .dataTables_paginate{
    padding-top: 0.75em;
    padding-bottom: 0.75em;
}

table.dataTable thead > tr > th.sorting::after, table.dataTable thead > tr > th.sorting_desc::after, table.dataTable thead > tr > th.sorting_asc::after {
	content: "";
	font-family: "Font Awesome 6 Free";
	font-weight: 900;
	background-image: none;
	margin-#{$dlab-pos-left}: 5px;
	font-size: calc(100% - 2px);
	opacity: 0.5;
}
table.dataTable thead > tr > th.sorting{
	&::after {
		content: "\f0dc";
	}
	&.sorting_desc::after {
		content: "\f0dd";
		opacity: 0.5;
	}
	&.sorting_asc::after {
		content: "\f0de";
		opacity: 0.5;
	}
}
table.dataTable thead > tr > th.sorting::before {
	content: none;
}

table.dataTable thead th, table.dataTable thead td{
    border-bottom: 1px solid var(--border);
    border-top: 0;
}

table.dataTable tfoot th, table.dataTable tfoot td{
    border-top: 0;
}
table.dataTable tbody tr.selected > *{
	color: var(--text-dark);
	border-color: var(--border)!important;
}
table.dataTable tbody tr, table.dataTable tbody td{
    background: transparent !important;
}
table.dataTable.hover > tbody > tr.selected:hover > *, table.dataTable.display > tbody > tr.selected:hover > *{
	box-shadow: unset!important;
}

table.dataTable thead th{
    color: var(--text-dark);
	white-space:nowrap;
	font-size:.9375rem;
	text-transform:capitalize;
    font-weight: 500;
	padding: 1.25rem 0.9375rem;
   
}
table.dataTable{
	& > thead > tr > th{
		padding: 1rem 0.9375rem;
		text-align: #{$dlab-pos-left};
		@include respond ('phone'){
			padding: 1rem 0.5375rem;
		}
	}
}

.dataTables_wrapper .dataTables_info{
	padding-top: 1rem;
}

table.dataTable tbody td{
    padding: .85rem 0.9375rem;
	font-size:.875rem;
	font-weight: 400;
	border-bottom:0;
	@include respond('phone') {
		padding: 0.5rem 0.3125rem;
	}
}

table.dataTable tr.selected{
    color: var(--primary);
}

table.dataTable tfoot th{
    color: var(--text-dark);
    font-weight: 500;
	font-size: .9375rem;
	text-transform: capitalize;
}
table.dataTable > tfoot > tr > th, table.dataTable > tfoot > tr > td{
	padding: 1rem 0.9375rem;
	text-align: #{$dlab-pos-left};
}
td.text-ov {
    white-space: nowrap;
}

.dataTables_wrapper .dataTables_paginate{
	align-items: center;
    display: flex;
	flex-flow:wrap;
	justify-content:center;
	@include respond ('phone'){
		flex-flow: nowrap;
	}
	.paginate_button.previous,.paginate_button.next{
		height:2.5rem;
		width: 2.5rem;
		border:0.0625rem solid var(--primary);
		border-radius:2.8125rem;
		padding:0 0 0.75rem;
		line-height:2.5rem;
		margin:0 0.625rem;
		display:inline-block;
		color:var(--primary)!important;
		&.current:hover,
		&.previous:hover,
		&.next:hover{
			color:$white!important;
			background:var(--primary)!important;
		}
		&.disabled{
			color:var(--primary)!important;
		}
	}
	span{
		.paginate_button{
			height:2.5rem;
			width:2.5rem;
			padding:0;
			margin-#{$dlab-pos-left}: 0rem;
			margin:0 0.125rem;
			line-height:2.5rem;
			text-align:center;
			border-radius:2.8125rem;
			color:var(--primary)!important;
			background:var(--rgba-primary-1);
			&.current,&:hover{
				color: #fff !important;
				background:var(--primary);
				border:1px solid transparent;	
				&:hover{
					color:$white!important;
					background:var(--primary)!important;
				}
			}
		}
	}
}

.dataTables_wrapper {
    input[type="search"], input[type="text"], select {
        border: 1px solid var(--border);
        padding: .3rem 0.5rem;
		height: 2.5rem;
        color: var(--text-dark);
        border-radius: $radius;
        
    }
	.dataTables_length{
		margin-bottom: 0.9375rem;
		.bootstrap-select{
			width: 5rem!important;
			margin: 0 0.3125rem;
		}
	}
}
table.dataTable.no-footer{
    border-bottom: 0;
}


.rounded-lg{
	min-width:1.875rem;
}
.dataTables_scroll{
    padding: 1rem 0;

    &Foot{
        padding-top: 1rem;
    }
}

.dataTablesCard{
	background-color:var(--card);
	border-radius:$radius;
	overflow: hidden;
	&.border-no{
		td{
			border-top:0!important;
		}
	}
}

.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter {
    @include media-breakpoint-down(md) {
        text-align: #{$dlab-pos-left};
    }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, 
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, 
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active{
	color: $body-color !important;
}
.dataTables_wrapper .dataTables_length, 
.dataTables_wrapper .dataTables_filter, 
.dataTables_wrapper .dataTables_processing, 
.dataTables_wrapper .dataTables_paginate {
    color: $body-color;
	border-radius:1rem;
	padding:0.125rem 0;
	@at-root [data-theme-version="dark"] & {
			color:$d-ctl;
	}
}
.paging_simple_numbers.dataTables_paginate {
	padding: 0.3125rem 0.3125rem;
}
.dataTables_wrapper .dataTables_paginate .paginate_button{
	color: $body-color !important;
}



table.dataTable.row-border tbody th, 
table.dataTable.row-border tbody td, 
table.dataTable.display tbody th, 
table.dataTable.display tbody td {
    border-color: $border-color;
	box-shadow: none!important;
    @at-root [data-theme-version="dark"] & {
        border-color: $d-border;
	   color:rgba(255,255,255,0.7);
    }
}

.dataTables_wrapper{
	.dataTables_length{
		.bootstrap-select{
			.dropdown-toggle{
				font-size: 0.813rem !important;
				padding: 0.625rem 1rem;
				line-height: 2;
			}
		}
		@at-root [data-theme-version="dark"] & {
			color:$d-ctl;
		}
	}
}
.dataTables_paginate.paging_simple_numbers{
	span{
		@include respond ('phone'){
			display:flex;
		}
	}
}

.table.dataTable.display > tbody > tr > td {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
} 

table.dataTable.row-border > tbody > tr > th, 
table.dataTable.row-border > tbody > tr > td, 
table.dataTable.display > tbody > tr > th, 
table.dataTable.display > tbody > tr > td{
	border-color: var(--border);
}

table.dataTable thead > tr > th.sorting::before, table.dataTable thead > tr > th.sorting::after, table.dataTable thead > tr > th.sorting_asc::before, table.dataTable thead > tr > th.sorting_asc::after, table.dataTable thead > tr > th.sorting_desc::before, table.dataTable thead > tr > th.sorting_desc::after, table.dataTable thead > tr > th.sorting_asc_disabled::before, table.dataTable thead > tr > th.sorting_asc_disabled::after, table.dataTable thead > tr > th.sorting_desc_disabled::before, table.dataTable thead > tr > th.sorting_desc_disabled::after, table.dataTable thead > tr > td.sorting::before, table.dataTable thead > tr > td.sorting::after, table.dataTable thead > tr > td.sorting_asc::before, table.dataTable thead > tr > td.sorting_asc::after, table.dataTable thead > tr > td.sorting_desc::before, table.dataTable thead > tr > td.sorting_desc::after, table.dataTable thead > tr > td.sorting_asc_disabled::before, table.dataTable thead > tr > td.sorting_asc_disabled::after, table.dataTable thead > tr > td.sorting_desc_disabled::before, table.dataTable thead > tr > td.sorting_desc_disabled::after {
	position: relative;
	display: inline-block;
	#{$dlab-pos-right}: 0;
}
table.dataTable > thead > tr > th, table.dataTable > thead > tr > td,
table.dataTable > tfoot > tr > th, table.dataTable > tfoot > tr > td{
	border-color: var(--border);
}