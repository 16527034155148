.nav-pills {
    .nav-link {
		border-radius: $radius-sm;
	    padding: 0.5rem 1rem;
		font-size: 14px;
		font-weight: 500;
		color: #5b5e81;
		text-transform: capitalize;
		@at-root [data-theme-version="dark"] & {
			color:$d-ctl;
		}
		&:hover{
			color:var(--primary);
		}
		@include respond ('phone'){
			padding: 0.5rem 0.9rem;
			margin-top:0.5rem;
		}
	}
}

.default-tab{
    .nav-link{
        background: transparent;
        border-radius: 0rem;
        font-weight: 500;
		i{
			display:inline-block;
			transform:scale(1.5);
			color: #5b5e81;
			
		}
		
		&:focus,
		&:hover,
		&.active{
			color: #495057;
			background-color: #fff;
			border-color: #dee2e6 #dee2e6 #fff #ebeef6;
			border-radius: $radius $radius 0 0;
			color:var(--primary);
			@at-root [data-theme-version="dark"] & {
				background-color: var(--rgba-primary-1);
				border-color: transparent transparent $d-border transparent;
			}
		}
    }
}

.custom-tab-1{
    .nav-link{
        background: transparent;
        border-radius: 0rem;
        font-weight: 500;
		border-bottom:0.1875rem solid transparent;
		border-width: 0 0rem 0.1875rem 0;
		
		i{
			display:inline-block;
			transform:scale(1.5);
			color:var(--primary);
		}
		&:focus,
		&:hover,
		&.active{
			color: #495057;
			background-color: #fff;
			border-color:var(--primary) ;
			border-radius:0;
			color: var(--primary);
			border-width: 0 0rem 0.1875rem 0;
			@at-root [data-theme-version="dark"] & {
				background-color: var(--rgba-primary-1);
			}
		}
    }
	
}
.nav-pills{
	
	&.light{
		.nav-link.active, 
		.show > .nav-link{
			background:var(--rgba-primary-1);
			color:var(--primary);
			box-shadow:none;
			@at-root [data-theme-version="dark"] & {
				background:var(--rgba-primary-1);
			}
		}	
	
	}
	.nav-link.active,
	&:hover	
	.show > .nav-link{
		background-color:var(--rgba-primary-1);
		color:var(--primary);
	}
}

.icon-tab{
	.nav-tabs{
		.nav-link{
			&.active{
				background-color:var(--body-bg);
				border-color:var(--border);
				i{
					color:var(--text-dark);
				}
			}
			&:hover{
				border-color:var(--border);
			}
		}
	}
}