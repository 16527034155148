.fc-h-event,.fc-v-event{
	background:var(--primary);
	border-radius: .42rem;
}
.fc-h-event .fc-event-title{
	color:$white;
}
.fc-theme-standard td, .fc-theme-standard th{
	border-color:var(--border);
}
.fc-unthemed .fc-h-event, .fc-unthemed .fc-event-dot {
    padding: 0;
    border-radius: .42rem;
}
.fc-theme-standard th{
	padding: .75rem .5rem;
    font-size: 1rem;
    font-weight: 500;
	color: #b5b5c3;
	@include respond ('phone'){
		font-size:14px;
		font-weight:400;
		padding:3px 0px;
	}
}
.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid,.fc-scrollgrid ,table{
	border-color:var(--border);
}

.fc-daygrid-dot-event{
	background:var(--card);
    border: 1px solid var(--border);
    -webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
	.fc-daygrid-event-dot{
		border-color:var(--primary);
	}
	@include respond ('tab-land'){
		width:auto;
		height:auto;
		flex-wrap: wrap;
	}
}
.fc-daygrid-dot-event .fc-event-title{
	font-weight:500;
}
.fc-event.bg-primary
,.fc-event.bg-success
,.fc-event.bg-warning
,.fc-event.bg-secondary
,.fc-event.bg-dark
,.fc-event.bg-info{
	color:$white!important;
	border-radius: 8px;
	.fc-daygrid-event-dot{
		border-color:$white;
	}
}

.fc .fc-scroller-liquid-absolute,.fc-scroller{
	position:relative;
	overflow: visible!important;
}

.fc .fc-button-group{
	&>.fc-button{
		color: #b5b5c3;
		background: 0 0;
		border: 1px solid var(--border);
		border-color:var(--border)!important;
		text-shadow: none!important;
		-webkit-box-shadow: none!important;
		box-shadow: none!important;
		text-transform: capitalize;
		&:focus,&:active,&:hover,&.fc-button-active{
			background:var(--primary);
			color:$white;
			
		}
		&:active{
			background: var(--primary);
		}
		
	}
}

.fc-button.fc-button-primary.fc-today-button{
	background:var(--primary);
	color:$white;
	border: 0;
    opacity: 1;
	text-transform: capitalize;
}
.fc-unthemed .fc-toolbar .fc-button.fc-button-active, .fc-unthemed .fc-toolbar .fc-button:active, .fc-unthemed .fc-toolbar .fc-button:focus{
	background: var(--primary);
    color: $white;
    border: 1px solid var(--primary);
    -webkit-box-shadow: none;
    box-shadow: none;
    text-shadow: none;
}
.fc .fc-toolbar-title {
    font-size: 20px;
    margin: 0;
}
.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0.5em;
}

.external-event{
	padding: 8px 10px;
	display:flex;
	align-items:center;
    border-radius: 5px;
	&:hover{
		&:before{
			background:$white!important;
		}
	}
}
.fc-event{
	overflow:hidden;
}

.fc .fc-view-harness{
	height: 800px!important;
	overflow: auto;
	@include respond ('tab-port'){
		width:800px!important;
	}
	
}
.fc .fc-toolbar.fc-header-toolbar{
	@include respond ('phone'){
		display:block;
		.fc-toolbar-chunk{
			display:flex;
			justify-content: center;
			&:first-child{
				justify-content:space-between;
			}
		}
		.fc-toolbar-title{
			margin-bottom: 8px;
		}
	}
}

#external-events{
	.external-event{
		text-align: #{$dlab-pos-left};
		font-size: 16px;
	}
}

.fc.app-fullcalendar{
	@include respond ('phone'){
		.fc-timegrid-slot-label{
			width:40px!important;
			font-size: 10px;
		}
		.fc-event, .external-event{
			font-size: 10px;
			margin:0;
			padding: 2px 0;
			text-align: center;
			line-height: 1.3;
		}
		.fc-col-header-cell-cushion {
			display: inline-block;
			padding: 2px 4px;
			font-size: 10px;
		}
	}
}
.fc-media-screen .fc-timegrid-event-harness{
	height:61px;
}
.fc .fc-media-screen {
    width: 100%;
    overflow: auto;
}
.app-fullcalendar,
.fullcalendar{
	overflow:auto;
}
.fc table{
	font-size:0.8rem;
}