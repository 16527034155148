.ribbon {
    position: absolute;
    z-index: 1;
    text-transform: uppercase;

    &__one {
        top: 0.9375rem;
        left: -0.6875rem;
        min-height: 1.25rem;
        min-width: 3.25rem;
        text-align: center;
        padding: 0.1875rem 0.625rem;
        background: #3AB54B;
        color: #fff;
        font-size: 0.625rem;

        &::after {
            position: absolute;
            width: 0;
            height: 0;
            border-top: 0.625rem solid #239132;
            border-left: 0.6875rem solid transparent;
            left: 0;
            content: "";
            bottom: -0.625rem;
        }
    }

    &__two {
        width: 3.125rem;
        height: 3.125rem;
        display: inline-block;
        background: $warning;
        line-height: 3.125rem;
        text-align: center;
        font-size: 1rem;
        color: $white;
        right: 0.9375rem;
        top: 0.9375rem;
        border-radius: 0.1875rem;

        
    }

    &__three {
        left: -1.875rem;
        top: 0.875rem;
        width: 6.875rem;
        height: 1.5625rem;
        background-color: $danger;
        clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
        transform: rotate(-45deg);
        color: $white;    
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.875rem;
    }

    &__four {
        left: 0.5rem;
        top: -0.5rem;
        width: 6.875rem;
        height: 3.125rem;
        background-color: var(--primary);
        z-index: auto;
        color: $white;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        
        &:after {
            right: -0.3125rem;
        }

        &:before {
            left: -0.3125rem;
        }

        // &:after, &:before {

        // }

        &:after, &:before {
            z-index: -1;
            background-color: var(--rgba-primary-1);
            top: 0.1875rem;
            transform: rotate(45deg);
            content: "";
            height: 0.625rem;
            width: 0.625rem;
            position: absolute;
        }
    }

    &__five {
        left: -1.875rem;
        top: 0.625rem;
        width: 6.875rem;
        height: 1.875rem;
        background-color: var(--primary);
        transform: rotate(-45deg);
        font-size: 0.75rem;
        color: $white;
        padding-bottom: 0.3125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;

        &::before {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 3.125rem 3.125rem 3.125rem;
            border-color: transparent transparent var(--primary) transparent;
            left: 0.25rem;
            top: -2.8125rem;
        }
    }

    &__six {
        left: 0;
        top: 1.125rem;
        width: 6.875rem;
        height: 2.375rem;
        background-color: var(--primary);
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
        display: flex;
        font-size: 1.25rem;
        align-items: center;
        justify-content: center;
        color: $white;
    }

    &-curve {
        position: absolute;
        top: 0;
        right: -0.375rem;
        width: 0.625rem;
        height: 0.6875rem;
        border-top: 0.6875rem solid #3AB54B;
        border-bottom: 0.625rem solid #3AB54B;
        border-right: 0.3125rem solid transparent;
    }
}