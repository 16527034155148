////////////////
// Compact Nav
////////////////

@media (min-width:47.9375rem) {
    [data-sidebar-style="compact"] {
		.nav-header {
			width: 11.25rem;
		}
		.dlabnav.follow-info .menu-scroll{
			height: calc(100% - 4.5rem);
		}
        .dlabnav {
            .header-profile{
				margin-bottom: 0.3125rem;
				&>a.nav-link{
					display:block;
					text-align:center;
					border:0;
					img{
						margin-bottom:0.3125rem;
					}
					.header-info{
						margin-#{$dlab-pos-left}:0!important;
						text-align:center;
						small{
							text-align:center!important;
						}
						display:none;
					}
				}
				.dropdown-menu{
					min-width:11rem;
				}
				a svg{
					display:unset!important;
				}
			}
			.main-menu{
				text-align:center;
			}
            .nav-user{
                display: none;
            }
        
            .metismenu {
			
                li {
                    text-align: center;
                    a {
                        padding: 0.7rem 0.5rem;
						svg{
						
							max-width: 1.3125rem;
							max-height: 1.3125rem;
							display: block;
							margin-#{$dlab-pos-left}: auto;
							margin-#{$dlab-pos-right}: auto;	
						}
						i{
							@include transitionMedium;
						}
                    }
					ul:after{
						content:none;
					}
					
					& > a{
						box-shadow: none;
						font-size: 0.9375rem;
					}
                }
            }
			
			.plus-box,.copyright{
				display: none;
			}
			.copyright{
			    padding: 0 1.25rem;
				margin-top: 1.25rem;
			}
			.header-info2{
				padding:0;	
				img{
					margin-#{$dlab-pos-left}:-1rem;	
				}
			}
			.sidebar-info{
				display:none!important;	
			}
			.header-profile2{
				margin: 0 3rem;	
			}
			.feature-box,
			.support-box{
				display:none;
			}
        }
		.header{
			.page-titles{
				padding-#{$dlab-pos-left}: 2.1rem;
			}
		}

        .nav-text {
            display: inline-block;
            margin-top: 0.3125rem;
        }

        .nav-label {
            &.first{
                display: none;
            }
        }

        .nav-badge {
            display: none;
        }

        .footer {
            padding-#{$dlab-pos-left}: 11.3rem;
        }

        .content-body {
            margin-#{$dlab-pos-left}: 10.5rem;
        }
		
		&[data-theme-version="dark"]{
			&[data-layout="horizontal"]{
				.dlabnav {
					.metismenu {
						 li {
							&>a i{
								color:$white;
							}
						 }
					}	
				}
			}
            &[data-layout="vertical"]{
                .dlabnav {
                    .metismenu {
                         li {
                             &>a i{
                               
                             }
                             &.mm-active{
                                 &>a i{
                                   background:var(--primary);
                                    color:$white;
                                }
                             }
                         }
                    }
                }
            }
		}
		&[data-layout="vertical"]{
			.dlabnav {
				.metismenu {
					 li {
						&:hover{
							&>a i{
								background:var(--primary);
								color:$white;
							}
						}	
					 }
				}	
			}
			.dlabnav .metismenu .has-arrow[aria-expanded=true]:after, .dlabnav .metismenu .mm-active > .has-arrow:after{
				margin-#{$dlab-pos-right}:0;	
				border-color: var(--primary);
			}
		}
    }
}
[data-layout="horizontal"][data-sidebar-style="compact"] {
	.footer {
		padding-#{$dlab-pos-left}: 0;
	}
	.content-body {
		margin-#{$dlab-pos-left}:0;
	}
	.dlabnav{
		margin-bottom:0;
	}
	.nav-header {
		width: 13.25rem;
		.brand-logo {
			padding-#{$dlab-pos-left}: 2.5rem;
			padding-#{$dlab-pos-right}: 2.5rem;
		}
	}
}
[data-layout="vertical"][data-sidebar-style="compact"] {
    .dlabnav {
        width: 11.25rem;
		z-index: 3;
        
        .metismenu {
		
            ul{
				margin-#{$dlab-pos-left}:0;
				border:0;
				background: rgba(0,0,0,0.02);
				padding: 0.625rem 0;
				
				@at-root [data-theme-version="dark"]#{&} {
					background:(255, 255, 255, 0.05);
				}
					
				a{
					&:before{
						content:none;
					}
				}
				
				ul a {
					padding: 0.625rem 0.9375rem;
					
				}
            }

            &>li {
				&>a{
					padding:1.2rem 0.5rem;
				}
                &>a.has-arrow::after {
                    top: 0.0625rem;
					display: inline-block;
					#{$dlab-pos-right}: auto;
					margin-#{$dlab-pos-left}: 0.3125rem;
					position: relative;
					width: 0.4375rem;
					height: 0.4375rem;
					border-width: 0.125rem 0rem 0rem 0.125rem;
					
					
					
                }
				&.mm-active {
					&>a {
						background:transparent;
						color:var(--primary);
					}
				}	
            }
        }

    }
}