


.badge{
   line-height: 1.2;
    border-radius: $radius-sm;
    font-weight: 600;
    padding: 0.25rem 0.625rem;
	border:0.0625rem solid transparent;
}

.badge-rounded{
    border-radius: 1.25rem;
    padding:0.1875rem 0.8125rem ;
}
.badge-circle{
    border-radius: 6.25rem;
    padding: 0.1875rem 0.4375rem;
}





.badge-outline-primary{
    border: 0.0625rem solid var(--primary);
    color: var(--primary);
}
.badge-outline-secondary{
    border: 0.0625rem solid $secondary;
    color: $secondary;
	
}
.badge-outline-success{
    border: 0.0625rem solid $success;
    color: $success;
}
.badge-outline-info{
    border: 0.0625rem solid $info;
    color: $info;
}
.badge-outline-warning{
    border: 0.0625rem solid $warning;
    color: $warning;
}
.badge-outline-danger{
    border: 0.0625rem solid $danger;
    color: $danger;
}
.badge-outline-light{
    border: 0.0625rem solid $border;
    color: $dark;
	
}
.badge-outline-dark{
    border: 0.0625rem solid $dark;
    color: $dark;
	
}

.badge-xs {
    padding: 0px 8px;
    line-height: 14px;
    border-radius: 0.375rem;
    font-weight: 500;
    height: 16px;
    min-height: 16px;
    font-size: .58rem;
}

.badge-sm {
	font-size: 0.6875rem;
    padding: 0.3125rem 0.5rem;
    line-height: 0.6875rem;
}

.badge-lg {
	padding: 0.25rem 0.5rem;
	border-radius: 0.5rem;
    font-weight: 400;
    font-size: 0.813rem;
    line-height: 1.4;
	&:hover{
		color: #fff;
	}
}


.badge-xl {
    font-size: 1rem;
    padding: 0rem 0.9375rem;
   line-height: 2;
}
.badge-default{
    background: #ADB6C7;
}
.badge-success {
    background-color: $success;
}
.badge-secondary {
    background-color: $secondary;
}
.badge-info {
    background-color: $info;
}
.badge-primary {
    background-color: var(--primary);
}
.badge-warning {
    background-color: $warning;
}
.badge-danger {
    background-color: $danger;
}
.badge-dark {
    background-color: $dark;
}
.badge-light {
    background-color: $light;
    color:#000!important;
}



.light.badge-default{
    background: #ADB6C7;
}
.light.badge-success {
    background-color:rgba($success,0.2);
	color:$success;
	
}
.light.badge-info {
    background-color:rgba($info,0.2);
	color:$info;
	
}
.light.badge-primary {
    background-color: var(--rgba-primary-1);
	color:var(--primary);
	
}
.light.badge-secondary {
    background: rgba($secondary,0.2);
	color:$secondary;
	
}
.light.badge-warning {
    background: rgba($warning,0.2);
	color:$warning;
	
}
.light.badge-danger {
    background: rgba($danger,0.2);
	color:$danger;
	
}
.light.badge-dark {
    background: rgba($dark,0.2);
	color:$white;
	
}





//

.bootstrap-label{
    .label{
        display: inline-block;
        margin-#{$dlab-pos-right}: 1rem;

        &:last-child{
            margin-#{$dlab-pos-right}: 0;
        }
    }
}

.badge-demo{

    .badge{
        margin-#{$dlab-pos-right}: 0.3125rem;
        margin-bottom: 0.3125rem;

        &:last-child{
            margin-#{$dlab-pos-right}: 0;
        }
    }
}

.bootstrap-badge-buttons{

    button{
        margin-#{$dlab-pos-right}: .2rem;
        margin-bottom: 1rem;

        &:last-child{
            margin-right: 0;
        }
    }
}
.badge-xs.style-1 {
    margin-#{$dlab-pos-left}: 1rem;
}