
.widget-timeline{

	.timeline {
		list-style: none;
		position: relative;
		&:before {
			top: 1.25rem;
			bottom: 0;
			position: absolute;
			content: " ";
			width: 0.1875rem;
			#{$dlab-pos-left}: 0.5625rem;
			margin-#{$dlab-pos-right}: -0.0938rem;
			background: var(--body-bg);
			
			
			
		}
		> li {
			margin-bottom: 0.9375rem;
			position: relative;
			&:before {
				content: " ";
				display: table;
			}
			&:after {
				content: " ";
				display: table;
				clear: both;
			}
			> {
				.timeline-panel {
					border-radius: $radius;
					padding: 0.9375rem 1.25rem;
					position: relative;
					display:block;
					margin-#{$dlab-pos-left}: 2.5rem;
					border-width:0.0625rem;
					border-style:solid;
					span{
						font-size: 0.75rem;
						display: block;
						margin-bottom: 0.3125rem;
						opacity: 0.8;
						letter-spacing: 0.0625rem;
					}
					p{
						font-size:0.875rem;
					}
					&:after{
						content: "";
						width: 0.625rem;
						height: 0.625rem;
						background: inherit;
						border-color: inherit;
						border-style: solid;
						border-width: 0rem 0rem 0.0625rem 0.0625rem;
						display: block;
						position: absolute;
						#{$dlab-pos-left}: -0.3125rem;
						transform: rotate(45deg);
						top: 0.9375rem;
					}
				}
				.timeline-badge {
					border-radius: 50%;
					height: 1.375rem;
					#{$dlab-pos-left}: 0;
					position: absolute;
					top: 0.625rem;
					width: 1.375rem;
					border-width: 0.125rem;
					border-style: solid;
					background:var(--card);
					padding:0.25rem;
					
					&:after{
						content:"";
						width:0.625rem;
						height:0.625rem;
						border-radius:100%;
						display:block;
					}
					
					
				}
			}
		}
	}
	
	.timeline-body > p {
		font-size: 0.75rem;
	}
  
  .timeline-badge {
    &.primary{
		border-color: var(--rgba-primary-1);
		
		&:after{
			background-color: var(--primary);
			box-shadow:0 0.3125rem 0.625rem 0 var(--rgba-primary-2);
		}
		& + .timeline-panel{
			background:var(--rgba-primary-1);
			border-color:var(--rgba-primary-1);
			
		}
    }
    &.success{
		border-color:rgba($success,0.2);
		
		&:after{
			background-color: $success !important;
			box-shadow:0 0.3125rem 0.625rem 0 rgba($success, 0.2);
		}
		& + .timeline-panel{
			background:rgba($success,0.2);
			border-color:rgba($success,0.2);
			
		}
    }
    &.warning{
		border-color:rgba($warning, 0.2);
		
		&:after{
			background-color: $warning !important;
			box-shadow:0 0.3125rem 0.625rem 0 rgba($warning, 0.2);
		}
		& + .timeline-panel{
			background:rgba($warning, 0.2);
			border-color:rgba($warning, 0.2);
			
		}
    }
    &.danger{
		border-color:rgba($danger,0.2);
		
		&:after{
		  background-color: $danger !important;
		  box-shadow:0 0.3125rem 0.625rem 0 rgba($danger, 0.2);
		}
		& + .timeline-panel{
			background:rgba($danger,0.2);
			border-color:rgba($danger,0.2);
			
		}
    }
	
    &.info{
		border-color:rgba($info,0.2);
		
		&:after{
			background-color: $info !important;
			box-shadow:0 0.3125rem 0.625rem 0 rgba($info, 0.2);
		}
		& + .timeline-panel{
			background:rgba($info,0.2);
			border-color:rgba($info,0.2);
			
		}
    }
    &.dark{
		border-color:rgba($dark, 0.2);
		
		&:after{
		  background-color: $dark !important;
		  box-shadow:0 0.3125rem 0.625rem 0 rgba($dark, 0.2);
		}
		& + .timeline-panel{
			background:rgba($dark, 0.2);
			border-color:rgba($dark, 0.2);
			
		}
    }
  }
	
	&.style-1{
		.timeline-panel{
			background:transparent;
		}
		.timeline {
			.timeline-badge {
				&.timeline-badge{
					& + .timeline-panel{
						background: transparent !important;
						border: 0;
						border-#{$dlab-pos-left} : 0.3125rem solid;
						border-radius: 0;
						padding: 0.3125rem 0.625rem 0.3125rem 0.9375rem;
						&:after{
							border:0;
							#{$dlab-pos-left}: -0.5625rem;
							width: 0.4375rem;
							height: 0.4375rem;
						}
					}
				}
				&.primary{
					& + .timeline-panel{
						border-color:var(--primary);
						&:after{
							background:var(--primary);
						}
					}
				}
				&.success{
					& + .timeline-panel{
						border-color:$success;
						&:after{
							background:$success;
						}
					}
				}
				&.warning{
					& + .timeline-panel{
						border-color:$warning;
						&:after{
							background:$warning;
						}
					}
				}
				&.danger{
					& + .timeline-panel{
						border-color:$danger;
						&:after{
							background:$danger;
						}
					}
				}
				&.info{
					& + .timeline-panel{
						border-color:$info;
						&:after{
							background:$info;
						}
					}
				}
				&.dark{
					& + .timeline-panel{
						border-color:$dark;
						&:after{
							background:$dark;
						}
					}
				}
			}
		}
	
	}  
  
}

  
  