[data-sidebar-style="overlay"] {
	&[data-layout="vertical"]{
		.dlabnav {
			border-radius:0 0 0 0!important;
			@include respond ('phone-land'){
				border-radius:0 0 0 0!important;
			}
		}
		.nav-header {
			border-radius:0;
		}
		.menu-toggle{
			.nav-header {
				border-radius: 0 0 0 0;
			}
		}
		.header{
			.page-titles{
				padding-left:2.9rem;
				@include respond ('phone'){
					padding-left:2.3rem;
				}
			}
			
		}
		.header:not(.home){
			&:after{
				background-position: unset;
			}
					}
	}
    .dlabnav {
        #{$dlab-pos-left}: -100%;
        @include transitionMedium;
		box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.2);
		.metismenu{
			& > li {
				padding: 0 1rem;
				& > a {
					font-size: 0.938rem;
					padding: 0.7375rem 1rem;
					color: #7f7184;
					border-radius: $radius;
					-webkit-transition: all 0.5s;
					-ms-transition: all 0.5s;
					transition: all 0.5s;
					i{
						height:auto;
						width:auto;
						line-height:1;
					}
				}
				&:hover > a{
					color:var(--primary);
					i{
						color:var(--primary);
					}
				}
				&.mm-active > a {
					background:var(--primary);
					color:$white;
					i{
						color:$white;
					}
				}
				@include respond ('phone'){
					padding:0rem 0.9375rem;
				}
				
			}
			ul a{
			    padding-top: .5rem;
				padding-bottom: .5rem;
				position: relative;
				font-size: 0.9375rem;
				padding-#{$dlab-pos-left}: 3.2rem;
				&:before{
					#{$dlab-pos-left}:1.5625rem;
				}
			}
			
			
		}
		
		
		@at-root [direction="rtl"]#{&} {
            left: auto;
            right: -100%;
        }
		
		@include respond ('phone-land'){
			.metismenu{
				&>li{
					&>a{
						font-size: 0.875rem;
						padding: 0.75rem 0.875rem;
						margin: 5px 0;
						i{
							font-size:1.125rem;
						}
					}
				}
				ul li a{
					padding-#{$dlab-pos-left}:2.8rem;
				}
			}
		}
    }
    .content-body {
        margin-#{$dlab-pos-left}: 0;
    }
    .nav-header {
        position: absolute;
        .hamburger.is-active {
            #{$dlab-pos-left}: 0;
            .line {
                background-color: var(--primary);
            }
        }
    }
    .menu-toggle {
        .nav-header {
            position: absolute;
            #{$dlab-pos-left}: auto;
        }
        .dlabnav {
            #{$dlab-pos-left}: 0;
			z-index: 3;
            
        }
    }
    .footer {
        padding-#{$dlab-pos-left}: 0;
    }
}

[data-sidebar-style="overlay"][data-header-position="fixed"] {
    .nav-header {
        position: fixed;
    }
}

[data-sidebar-position="fixed"][data-header-position="fixed"] {
    .nav-header {
        position: fixed;
    }
}