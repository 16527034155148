.accordion-button:not(.collapsed) {
    color: inherit;
    background: var(--card);
	
}
.accordion-button.collapsed {
    border-bottom-width: 1px;
}

.accordion {
	// accordion primary
	&-primary{
		.accordion-button {
			&.collapsed{
				background: var(--rgba-primary-1);
				border-color:var(--rgba-primary-1);
				color: var(--primary);
				box-shadow:none;
				
			}
			background:var(--primary);
			border-color:var(--primary);
			color: $white;
			box-shadow: var(--rgba-primary-2) 0px 8px 24px;
			
		}
	}
	&-primary-solid{
		.accordion-button {
			&.collapsed{
				background:var(--rgba-primary-1);
				border-color:var(--rgba-primary-1);
				color: var(--primary);
				box-shadow:none;
				border-bottom-#{$dlab-pos-left}-radius:$radius;
				border-bottom-#{$dlab-pos-right}-radius:$radius;
				
			}
			background:var(--primary);
			border-color:var(--primary);
			color: $white;
			box-shadow:0 -0.625rem 1.25rem 0 var(--rgba-primary-1);
			border-bottom-#{$dlab-pos-left}-radius:0;
			border-bottom-#{$dlab-pos-right}-radius:0;
		}
		.accordion-body {
            border: 0.125rem solid var(--primary);
            border-top: none;
			box-shadow:0 0.9375rem 1.25rem 0 var(--rgba-primary-1);
			border-bottom-#{$dlab-pos-left}-radius:$radius;
			border-bottom-#{$dlab-pos-right}-radius:$radius;
		}
	}

	&-danger{
		.accordion-button {
			&.collapsed{
				background: $danger-light;
				border-color: $danger-light;
				color: #211c37;
				box-shadow:none;
			}
			background:$danger;
			border-color:$danger;
			color: $white;
			box-shadow:0 0.9375rem 1.25rem 0 rgba($danger, 0.15);
		}
	}
	&-danger-solid{
		.accordion-button {
			&.collapsed{
				background: $danger-opacity;
				border-color: $danger-opacity;
				color: var(--text-dark);
				box-shadow:none;
				border-bottom-#{$dlab-pos-left}-radius:$radius;
				border-bottom-#{$dlab-pos-right}-radius:$radius;
				
			}
			background:$danger;
			border-color:$danger;
			color: $white;
			box-shadow:0 -0.625rem 1.25rem 0 rgba($danger, 0.15);
			border-bottom-#{$dlab-pos-left}-radius:0;
			border-bottom-#{$dlab-pos-right}-radius:0;
		}
		.accordion-body {
            border: 0.125rem solid $danger;
            border-top: none;
			box-shadow:0 0.2rem 1rem 0 rgba($danger, 0.15);
			border-bottom-#{$dlab-pos-left}-radius:$radius;
			border-bottom-#{$dlab-pos-right}-radius:$radius;
		}
	}


    //a single accrodion
    &-item {
        margin-bottom: 1.25rem;
        border: 0;
        background-color: transparent;
        &:first-of-type {
            .accordion-button {
                border-top-#{$dlab-pos-left}-radius : $radius;
                border-top-#{$dlab-pos-right}-radius: $radius;
            }
        }
        &:last-of-type {
            .accordion-button.collapsed {
                border-bottom-#{$dlab-pos-left}-radius : $radius;
                border-bottom-#{$dlab-pos-right}-radius: $radius;
            }
        }
    }

    //accrodion header styles
    &-button {
        padding:.75rem 1.75rem;
        border: 0.0625rem solid var(--border);
        cursor: pointer;
        position: relative;
        color: var(--text-dark);
        font-weight: 400;
		border-radius:$radius;
		--bs-accordion-btn-bg : var(--card);
		@include transitionMedium;
		&:focus{
			z-index: 2;
		}
       
        @include respond('laptop'){
			padding:.75rem 1.75rem;
		}
        

        //set the indicator font family
        &-indicator {
            &.indicator_bordered {
                display: inline-block;
                width: 1.5625rem;
                text-align: center;
                height: 1.5625rem;
                border: 0.0625rem solid var(--border);
                border-radius: 50%;
                line-height: 1.5625rem;
            }
        }

        &:not(.collapsed){
			&::after {
				content: "\e622";
				background-image:none;
				transform: rotate(0) translateY(-50%);
				
			}

			//icon style
			&.style_two {
				&::after {
					content: "\e648";
				}
			}
        }
		&::after {
			content: "\e61a";
			font-family: 'themify';
			position: absolute;
			#{$dlab-pos-right}: 1.5625rem;
			top: 50%;
			transform: translateY(-50%);
			background-image: none !important;
			
			width: auto;
			height: auto;
			
		}
        &.collapsed {
			

			//icon style
			&.style_two {
				&::after {
					content: "\e64b";
				}
			}
        }
    }

    //accordion body styles
    &-body {
            padding: 0.875rem 1.25rem;
			    
    }
	&-collapse{
		border: 0;
	}
    //accrodion with border
    &-bordered {
        .accordion-body {
            border: 0.0625rem solid var(--border);
            border-top: none;
			border-bottom-#{$dlab-pos-left}-radius:$radius;
			border-bottom-#{$dlab-pos-right}-radius:$radius;
        }
		.accordion-button.collapsed{
			border-radius:$radius;
		}
		.accordion-button{
			border-bottom-#{$dlab-pos-left}-radius:0;
			border-bottom-#{$dlab-pos-right}-radius:0;
		}
		
    }

    //accrodion without space
    &-no-gutter {
        .accordion-item {
            margin-bottom: 0;

            .accordion-button {
                &.collapsed {
                    border-bottom: none;
                }
            }

            &:last-child {
                .accordion-button {
                    border-bottom: 0.0625rem solid var(--border);
                }
            }
            
        }
    }

    //not gutter and bordered
    &-no-gutter.accordion-bordered {
		
        .accordion-item {
            &:not(:last-child) {
                .accordion-body {
                    border-bottom: none;
                }
            }
			.accordion-button{
				box-shadow:none;
			}
        }
    }

    //indicator in left positon
    &-left-indicator {
        .accordion-button {
			
			box-shadow:none;
            &-text {
                padding-#{$dlab-pos-left}: 2.5rem;
            }


            &:after {
                
                #{$dlab-pos-left}: 1rem;
            }
			.accordion-header-text{
				padding-#{$dlab-pos-left}:2rem;
			}
        }
    }
	&-bordered{
		 .accordion-button {
			box-shadow:none;
		 }
	}

    //with icon
    &-with-icon {
        .accordion-button {
			
			box-shadow:none;
            &-text {
                padding-#{$dlab-pos-left}: 2.5rem;
				
            }

           .accordion-header-icon {
                position: absolute;
                #{$dlab-pos-right}: auto;
                #{$dlab-pos-left}: 1.5625rem;
                font-family: 'themify';
				
                &::before {
                    content: "\e645";
                }
            }
			.accordion-header-text{
				padding-#{$dlab-pos-left}:2rem;
			}
        }

    }

    //with header background
    &-header-bg {
        .accordion-header {
			.accordion-button{
				
				background-color: var(--light);
				
			}
            
            
            &-primary {
				.accordion-button{
					background-color: var(--primary);
					color: $white;
					
				}
            }
            
            &-info {
				.accordion-button{
					background-color: $info;
					color: $white;
					border-color:$info;
					
				}
            }

            &-success {
				.accordion-button{
					background-color: $success;
					color: $white;
					border-color:$success;
					
				}
            }
        }
    }

    //with header background and no space
    &-header-bg.accordion-no-gutter {
        .accordion-button {
            border-color: transparent;
			border-radius: 0;
			box-shadow:none;
        }
		.accordion-item{
			&:first-child{
				.accordion-button{
					border-top-#{$dlab-pos-left}-radius:$radius;
					border-top-#{$dlab-pos-right}-radius:$radius;
				}
			}
			&:last-child{
				.accordion-button{
					border-bottom-#{$dlab-pos-left}-radius:$radius;
					border-bottom-#{$dlab-pos-right}-radius:$radius;
				}
			}
		}
    }
	&.accordion-no-gutter {
        .accordion-button {
            border-radius: 0;
			box-shadow:none;
			
        }
		.accordion-button.collapsed {
			border-radius: 0;
		}
		.accordion-body{
			border-radius: 0;
		}
		.accordion-item{
			&:first-child{
				.accordion-button{
					border-top-#{$dlab-pos-left}-radius:$radius;
					border-top-#{$dlab-pos-right}-radius:$radius;
				}
			}
			&:last-child{
				.accordion-button.collapsed{
					border-bottom-#{$dlab-pos-left}-radius:$radius;
					border-bottom-#{$dlab-pos-right}-radius:$radius;
				}
				.accordion-body{
					border-bottom-#{$dlab-pos-left}-radius:$radius;
					border-bottom-#{$dlab-pos-right}-radius:$radius;
				}
			}
		}
    }
    //with solid background
    &-solid-bg {
        .accordion-button {
			box-shadow:none;
            border-color: transparent;
            background-color:var(--rgba-primary-1);
			border-bottom-#{$dlab-pos-left}-radius:0;
			border-bottom-#{$dlab-pos-right}-radius:0;
			
            
			&.collapsed{
				border-radius:$radius;
			}
        }
		
        .accordion-body {
            border-color: transparent;
            background-color:var(--rgba-primary-1);
			border-bottom-#{$dlab-pos-left}-radius:$radius;
			border-bottom-#{$dlab-pos-right}-radius:$radius;
          
        }
    }

    //active header styles
    &-active-header {
        .accordion-button {
			
			box-shadow:none;
            &:not(.collapsed) {
                background-color: $info;
                border-color: $info;
                color: $white;
            }
        }
    }

    //shadow in header
    &-header-shadow {
        .accordion-button {
            border: none;
            box-shadow: 0 0 0.9375rem -0.1875rem rgba($color: $black, $alpha: .3);
			
        }
    }

    //stylishly rounded borders
    &-rounded-stylish {
        .accordion-button {
            border-top-#{$dlab-pos-left}-radius: 0.375rem;
            border-top-#{$dlab-pos-right}-radius: 0.375rem;
			box-shadow:none;
			
        }

        .accordion-body {
            border-bottom-#{$dlab-pos-left}-radius: 0.375rem;
            border-bottom-#{$dlab-pos-right}-radius: 0.375rem;
        }
    }

    //smothly rounded accrodion header
    &-rounded {
        .accordion-button {
            border-radius: 0.3125rem;
        }
    }

    //accordion gradient
    &-gradient {
        .accordion-button {
            color: $white;
            background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%,rgba(103, 25, 255, .85) 100%);
            border-color: transparent;
			border-bottom-#{$dlab-pos-left}-radius: 0;
            border-bottom-#{$dlab-pos-right}-radius: 0;
			box-shadow:none;
			&.collapsed{
				border-bottom-#{$dlab-pos-left}-radius: 0.375rem;
				border-bottom-#{$dlab-pos-right}-radius:0.375rem;
			}
        }

        .accordion-body {
            color: $white;
            background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%,rgba(103, 25, 255, .85) 100%);
            border-color: transparent;
        }
    }
	&-body{
		font-size: .85rem;
	}	
} 

.accordion-button:focus{
	border-color: var(--rgba-primary-3);
}

.accordion-left-indicator {
	.accordion-button {
		padding-left: 3rem;
	}
}


.navbar-nav{
    cursor: pointer;
	.active{
		.scroll{
			color:var(--primary);
			font-weight:500;			
		}
		&:after{
			background-color:var(--primary);
		}		
	}
	.scroll{
		&.active{
			color:var(--primary);
			font-weight:500;			

			&:after{
				background-color:var(--primary);
			}
		}
	}

}

.accordion-header-bg {
	&.box-shadow-none {
		.accordion-button {
			box-shadow: none;
		}
	}
}

.dzm-tabs {
	border-bottom: none;
	.nav-link {
		color: var(--text);
		border-radius: $radius;
		font-weight: 500;
		&.active{
			background: var(--rgba-primary-1);
			color: var(--primary);
			box-shadow: none;	
			border-color: transparent;
			// &:hover{
			// 	color : var(--primary);
			// 	border: none;
				
			// }
		}
		&:hover{
			color : var(--primary);
			border: 1px solid transparent;
		}
		// &:focus-visible{
		// 	box-shadow: none;
		// }
	}
}